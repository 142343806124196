import React from 'react';
import { css } from '@emotion/react';
import { splitArrayIntoN } from '@common/utils';
import { Section } from '@customTypes/event';
import SmallCardStoryItem from '@/components/main/common/storyItem/SmallCardStoryItem';

import SectionTitle from '../title/SectionTitle';
import { CarouselStory, LayoutForLazyLoading } from '@components/commons';
import StoryWrapper from '../StoryWrapper';
import { GenerationStoryFragment } from '@/operations/queries/home/__generated__/useGenerationStoryList.generated';

interface IStoryList {
  section: Section;
  data: GenerationStoryFragment;
  title?: string;
}

const MultiLineStoryList = ({ data, section, title }: IStoryList) => {
  return (
    <LayoutForLazyLoading>
      <StoryWrapper>
        <SectionTitle title={title || data.title} />
        <CarouselStory section={section}>
          {splitArrayIntoN(data.stories, 2)?.map((item, index) => (
            <div css={wrapper} key={item[0].storyId}>
              <SmallCardStoryItem data={item[0]} index={index} />
              {item[1] && <SmallCardStoryItem data={item[1]} index={index} />}
            </div>
          ))}
        </CarouselStory>
      </StoryWrapper>
    </LayoutForLazyLoading>
  );
};

const wrapper = css`
  display: flex;
  flex-direction: column;
  margin-left: 0.75em;
  gap: 1em;
`;

export default MultiLineStoryList;
