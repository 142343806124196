import React from 'react';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import useTranslation from 'next-translate/useTranslation';

interface ITopLabel {
  ranking?: number;
}

const TopLabel = ({ ranking }: ITopLabel) => {
  const { t } = useTranslation();

  return (
    <div css={wrapper}>
      <div className="text">{t('home_screen_title_top_and_number')}</div>
      <div className="text bold">{ranking}</div>
    </div>
  );
};

const wrapper = css`
  width: 2rem;
  height: 2rem;
  background: ${theme.colors.primary500};
  border-radius: 0.25rem 0 0.25rem 0;
  color: black;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.125rem;

  .text {
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.5625rem;
    line-height: 0.75rem;
    font-family: 'Lato';
  }

  .text.bold {
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 0.75rem;
    letter-spacing: -0.0187rem;
    color: ${theme.colors.white};
  }
`;

export default TopLabel;
