import * as Types from '../../../../baseType';

import { gql } from '@apollo/client';
import { StoryVerticalCoverDtoFragmentDoc } from '../../../fragments/__generated__/home.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdultStoryListQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  playType?: Types.InputMaybe<Types.Story_Play_Type>;
  sorting?: Types.InputMaybe<Types.Story_Sorting>;
}>;


export type AdultStoryListQuery = { __typename?: 'Query', listStoryByWebV3: { __typename?: 'ListableStory', totalCount: number, page: number, pageSize: number, list: Array<{ __typename?: 'Story', type: Types.Story_Type, storyId: number, name: string, authorName: string, onHiatus: boolean, isFinished: boolean, isNew: boolean, isUp: boolean, isTimeLeapFree: boolean, playType: Types.Story_Play_Type, representedAt?: any | null, sectionType: Types.Story_Section_Type, isAdult: boolean, mainImageFile?: { __typename?: 'File', link: string } | null, storyWeekdays?: Array<{ __typename?: 'StoryWeekday', weekday: Types.Story_Weekday }> | null, genreKey?: { __typename?: 'StoryGenre', text: { __typename?: 'LocalizeKey', key: string } } | null, language?: { __typename?: 'Language', display: string, code: string } | null }> } };


export const AdultStoryListDocument = gql`
    query AdultStoryList($page: Int, $playType: STORY_PLAY_TYPE, $sorting: STORY_SORTING) {
  listStoryByWebV3(
    data: {page: $page, playType: $playType, pageSize: 15, uiSectionType: Adult, sorting: $sorting}
  ) {
    totalCount
    page
    pageSize
    list {
      ...StoryVerticalCoverDto
    }
  }
}
    ${StoryVerticalCoverDtoFragmentDoc}`;

/**
 * __useAdultStoryListQuery__
 *
 * To run a query within a React component, call `useAdultStoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdultStoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdultStoryListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      playType: // value for 'playType'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useAdultStoryListQuery(baseOptions?: Apollo.QueryHookOptions<AdultStoryListQuery, AdultStoryListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdultStoryListQuery, AdultStoryListQueryVariables>(AdultStoryListDocument, options);
      }
export function useAdultStoryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdultStoryListQuery, AdultStoryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdultStoryListQuery, AdultStoryListQueryVariables>(AdultStoryListDocument, options);
        }
export type AdultStoryListQueryHookResult = ReturnType<typeof useAdultStoryListQuery>;
export type AdultStoryListLazyQueryHookResult = ReturnType<typeof useAdultStoryListLazyQuery>;
export type AdultStoryListQueryResult = Apollo.QueryResult<AdultStoryListQuery, AdultStoryListQueryVariables>;