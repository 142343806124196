import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useQuery } from '@apollo/client';
import { GET_USER } from '@api/user';
import { GetUser } from '@customTypes/user';
import { css } from '@emotion/react';

import SectionTitle from '@components/main/common/title/SectionTitle';
import StoryWrapper from '@components/main/common/StoryWrapper';
import SmallStoryItem from '../../common/storyItem/SmallStoryItem';
import { formatMoreLink } from '@/common/utils';
import { CarouselStory } from '@/components/commons';
import { useUserHistoryListQuery } from '@/operations/queries/home/useUserHistoryList';

const PlayedStory = () => {
  const { t } = useTranslation();
  const { data: userData } = useQuery<GetUser>(GET_USER);
  const { data } = useUserHistoryListQuery();

  if (
    !data?.listUserStoryHistoryV3.list.length ||
    userData?.getUser.isAnonymous
  ) {
    return null;
  }

  return (
    <StoryWrapper>
      <SectionTitle
        title={t('home_screen_title_read_with_nickname', {
          value: userData?.getUser.name,
        })}
        showMoreButton
        moreLink={formatMoreLink({ sectionName: 'history' })}
      />
      <div css={wrapper}>
        <CarouselStory section="홈_지난스토리">
          {data.listUserStoryHistoryV3.list?.map((item, index) => (
            <div className="item" key={item.story.storyId}>
              <SmallStoryItem
                key={item.story.storyId}
                section={'홈_지난스토리'}
                data={item.story}
                index={index}
                hasTitle
                hasMoreIcon
                userHistoryId={item.userStoryHistoryId}
              />
            </div>
          ))}
        </CarouselStory>
      </div>
    </StoryWrapper>
  );
};

const wrapper = css`
  padding: 0 1rem;

  .item {
    margin-right: 0.625rem;
  }
`;

export default PlayedStory;
