import { gql } from '@apollo/client';
import { useGetUserQuery as useQuery } from './__generated__/getUser.generated';

const TERMS_DTO = gql`
  fragment TermsDto on NewTermsToAcceptV2 {
    TOS {
      termsId
      link
    }
    CollectionAndUseOfPrivacy {
      termsId
      link
    }
    PP {
      termsId
      link
    }
  }
`;

export const GET_USER = gql`
  query getUser {
    getUser {
      userId
      name
      isAnonymous
      email
      verifiedAt
      currentCoin
      updateToAuthorAt
      authorName
      priv
      ssoLogins {
        sId
        userId
        ssoProvider
        ssoId
        profile
      }
      isExpiredCertification
      certificatedAt
      birthDateToken
      countryCode
      myStory {
        stat {
          numStory
          numViews
          numChapter
          numViews
          numLikes
        }
      }
      registeredAt
      isTester
      geo {
        country
        timezone
        region
      }
      systemLanguage {
        code
        display
      }
      newTermsToAcceptV2 {
        ...TermsDto
      }
    }
  }
  ${TERMS_DTO}
`;

export const useGetUserQuery = () => {
  const { data, loading } = useQuery();

  return { data, loading };
};
