import * as Types from '../../../../baseType';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FeaturedDtoFragment = { __typename?: 'Featured', storyId: number, story: { __typename?: 'Story', name: string }, coverImageFile?: { __typename?: 'ImageFile', link: string } | null };

export type FeaturedStoryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FeaturedStoryQuery = { __typename?: 'Query', getFeatured?: { __typename?: 'Featured', storyId: number, story: { __typename?: 'Story', name: string }, coverImageFile?: { __typename?: 'ImageFile', link: string } | null } | null };

export const FeaturedDtoFragmentDoc = gql`
    fragment FeaturedDto on Featured {
  story {
    name
  }
  storyId
  coverImageFile {
    link
  }
}
    `;
export const FeaturedStoryDocument = gql`
    query FeaturedStory {
  getFeatured {
    ...FeaturedDto
  }
}
    ${FeaturedDtoFragmentDoc}`;

/**
 * __useFeaturedStoryQuery__
 *
 * To run a query within a React component, call `useFeaturedStoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedStoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturedStoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeaturedStoryQuery(baseOptions?: Apollo.QueryHookOptions<FeaturedStoryQuery, FeaturedStoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeaturedStoryQuery, FeaturedStoryQueryVariables>(FeaturedStoryDocument, options);
      }
export function useFeaturedStoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeaturedStoryQuery, FeaturedStoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeaturedStoryQuery, FeaturedStoryQueryVariables>(FeaturedStoryDocument, options);
        }
export type FeaturedStoryQueryHookResult = ReturnType<typeof useFeaturedStoryQuery>;
export type FeaturedStoryLazyQueryHookResult = ReturnType<typeof useFeaturedStoryLazyQuery>;
export type FeaturedStoryQueryResult = Apollo.QueryResult<FeaturedStoryQuery, FeaturedStoryQueryVariables>;