import React from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import Button from '@components/commons/button/Button';
import { useIntersection } from '@hooks/useIntersection';

import SectionTitle from '../../common/title/SectionTitle';

const StoryRecommendation = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const [isView, setRef] = useIntersection();

  return (
    <section css={container} ref={setRef}>
      {isView && (
        <>
          <SectionTitle title={t('home_screen_title_recommend')} />
          <div className="recommendation-content">
            <Button
              title={t('home_screen_button_recommend_story')}
              customCSS={buttonStyle}
              isFill={false}
              onClick={() => router.push('/auth')}
            />
            <p className="recommendation-desc">
              {t('home_screen_description_more_infomation_for_story_recommand')}
            </p>
          </div>
        </>
      )}
    </section>
  );
};

const buttonStyle = css`
  width: 21.4375rem;
  height: 2.25rem;
  border-radius: 0.25rem;
  ${theme.font.header.subhead2};
  color: ${theme.colors.white};
  margin-bottom: 1rem;
  background: ${theme.colors.gray900};
  cursor: pointer;

  @media screen and (max-width: ${theme.devices.mobile}) {
    width: 100%;
  }
`;

const container = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2.5rem 0.9375rem 1rem;

  .recommendation-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .recommendation-desc {
      ${theme.font.body.body1};
      color: ${theme.colors.gray600};
      padding-bottom: 1.5em;
    }
  }
`;

export default StoryRecommendation;
