import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { usePlayTypeContext } from '@/provider/PlayTypeProvider';
import { useHomeTabGenreStoryListQuery } from '@/operations/queries/home/__generated__/useGetHomeTabGenreStoryList.generated';
import { formatMoreLink, getHomeSectionName } from '@common/utils';
import { Story_Play_Type } from '@/baseType';

import GenreStoryGrid from '@components/main/home/grid/GenreStoryGrid';
import SmallStoryItem from '@components/main/common/storyItem/SmallStoryItem';
import StoryWrapper from '@components/main/common/StoryWrapper';
import SectionTitle from '@components/main/common/title/SectionTitle';
import { GenreInfoDtoFragment } from '@/operations/fragments/__generated__/home.generated';
import { useSectionStoryGenresQuery } from '@/operations/queries/home/__generated__/useHomeStoryGenres.generated';

const GenreStoryList = () => {
  const { data } = useSectionStoryGenresQuery({
    variables: {
      tab: 'Home',
    },
  });

  return (
    <>
      {data?.sectionStoryGenres.map((genre) => (
        <GenreStory genre={genre} key={genre.type} />
      ))}
    </>
  );
};

const GenreStory = ({ genre }: { genre: GenreInfoDtoFragment }) => {
  const { t } = useTranslation();
  const { playType: homeTabType } = usePlayTypeContext();
  const { data } = useHomeTabGenreStoryListQuery({
    variables: {
      genre: genre.type,
      playType: homeTabType,
    },
  });

  if (!data?.listStoryByWebV3.list.length) {
    return null;
  }

  return (
    <StoryWrapper key={genre.type}>
      <SectionTitle
        title={t(genre.title?.key ?? '') || '-'}
        showMoreButton
        moreLink={formatMoreLink({
          sectionName: 'genre',
          playType: homeTabType,
          genreType: genre.type,
        })}
      />
      <GenreStoryGrid
        section={getHomeSectionName({
          playType: homeTabType,
          section: genre.text.ko_KR,
        })}
      >
        <>
          {data?.listStoryByWebV3.list.slice(0, 6).map((item, index) => (
            <SmallStoryItem
              key={item.storyId}
              section={getHomeSectionName({
                playType: homeTabType,
                section: genre.text.ko_KR,
              })}
              data={item}
              index={index}
              hasTitle
              showInteractiveBanner={
                item.playType === Story_Play_Type.Interactive && !homeTabType
              }
            />
          ))}
        </>
      </GenreStoryGrid>
    </StoryWrapper>
  );
};

export default GenreStoryList;
