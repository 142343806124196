import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { css } from '@emotion/react';
import { formatMoreLink } from '@/common/utils';
import { usePlayTypeContext } from '@/provider/PlayTypeProvider';
import { useFavoriteStoryListQuery } from '@/operations/queries/home/useFavoriteStoryList';
import { CarouselStory } from '@components/commons';
import StoryWrapper from '@components/main/common/StoryWrapper';
import SectionTitle from '@components/main/common/title/SectionTitle';
import SmallStoryItem from '../../common/storyItem/SmallStoryItem';
import { useGetUserQuery } from '@/operations/queries/user/getUser';

const FavoriteStory = () => {
  const { t } = useTranslation();
  const { playType } = usePlayTypeContext();

  const { data: userData } = useGetUserQuery();
  const { data } = useFavoriteStoryListQuery({
    page: 1,
  });

  if (!data?.listUserLikeStoryBundle.list.length) {
    return null;
  }

  return (
    <StoryWrapper>
      <SectionTitle
        title={
          userData?.getUser?.isAnonymous || !userData?.getUser?.name
            ? t('home_screen_title_favorite')
            : t('home_screen_title_favorite_with_nickname', {
                value: userData?.getUser?.name,
              })
        }
        moreLink={formatMoreLink({
          sectionName: 'favorite',
          playType: playType,
        })}
        showMoreButton={true}
      />
      <div css={wrapper}>
        <CarouselStory section="홈_좋아한작품">
          {data?.listUserLikeStoryBundle.list.map((item, index) => (
            <div className="item" key={item.storyId}>
              <SmallStoryItem
                section="홈_좋아한작품"
                index={index}
                data={item}
                hasTitle
              />
            </div>
          ))}
        </CarouselStory>
      </div>
    </StoryWrapper>
  );
};

const wrapper = css`
  padding: 0 1rem;

  .item {
    margin-right: 0.625rem;
  }
`;

export default FavoriteStory;
