import React from 'react';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { usePlayTypeContext } from '@/provider/PlayTypeProvider';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { NextBtn } from 'public/assets';
import { Story_Play_Type } from '@/baseType';
import {
  touchInteractiveMoreChallenge,
  touchNovelMoreChallenge,
} from '@lib/eventManager';

interface ITextWithColor {
  text: string;
  coloredText: string;
}

interface ISectionTitle {
  title: string | string[];
  desc?: string | ITextWithColor;
  showMoreButton?: boolean;
  isTextBlack?: boolean;
  descLink?: string;
  moreLink?: string;
}

const SectionTitle = ({
  title,
  showMoreButton = false,
  isTextBlack = false,
  descLink,
  moreLink,
}: ISectionTitle) => {
  const { t } = useTranslation();
  const { playType } = usePlayTypeContext();

  const handleMoreButtonClick = () => {
    if (playType === Story_Play_Type.Interactive) {
      touchInteractiveMoreChallenge();
      return;
    }
    if (playType === Story_Play_Type.Epub) {
      touchNovelMoreChallenge();
      return;
    }
  };

  return (
    <div css={container({ isTextBlack, descLink })}>
      {typeof title === 'string' ? (
        <h2 className="section-title">{title}</h2>
      ) : (
        <>
          {title.map((item) => (
            <h2 key={item} className="section-title">
              {item}
            </h2>
          ))}
        </>
      )}
      {showMoreButton && (
        <Link href={moreLink ?? ''}>
          <a className="discover-button" onClick={handleMoreButtonClick}>
            <span className="discover-text">{t('more_screen_title_more')}</span>
            <NextBtn width={16} height={16} stroke={theme.colors.gray400} />
          </a>
        </Link>
      )}
    </div>
  );
};

interface IContainer {
  isTextBlack: boolean;
  descLink?: string;
}

const container = ({ isTextBlack, descLink }: IContainer) => css`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;

  .section-title {
    ${theme.font.header.headline2};
    color: ${isTextBlack ? theme.colors.white : theme.colors.gray900};
  }

  .discover-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-shrink: 0;

    .discover-text {
      ${theme.font.body.body1};
      color: ${theme.colors.gray400};
    }
  }
`;

export default SectionTitle;
