import * as Types from '../../../../baseType';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GenerationStoryItemFragment = { __typename?: 'Story', type: Types.Story_Type, name: string, storyId: number, sectionType: Types.Story_Section_Type, isUp: boolean, isAdult: boolean, shortDesc?: string | null, authorName: string, playType: Types.Story_Play_Type, isFinished: boolean, onHiatus: boolean, mainImageFile?: { __typename?: 'File', link: string } | null };

export type GenerationStoryFragment = { __typename?: 'HomeSection', title: string, key?: string | null, stories: Array<{ __typename?: 'Story', type: Types.Story_Type, name: string, storyId: number, sectionType: Types.Story_Section_Type, isUp: boolean, isAdult: boolean, shortDesc?: string | null, authorName: string, playType: Types.Story_Play_Type, isFinished: boolean, onHiatus: boolean, mainImageFile?: { __typename?: 'File', link: string } | null }> };

export type GenerationStoryListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GenerationStoryListQuery = { __typename?: 'Query', getHomeSections: Array<{ __typename?: 'HomeSection', title: string, key?: string | null, stories: Array<{ __typename?: 'Story', type: Types.Story_Type, name: string, storyId: number, sectionType: Types.Story_Section_Type, isUp: boolean, isAdult: boolean, shortDesc?: string | null, authorName: string, playType: Types.Story_Play_Type, isFinished: boolean, onHiatus: boolean, mainImageFile?: { __typename?: 'File', link: string } | null }> }> };

export const GenerationStoryItemFragmentDoc = gql`
    fragment GenerationStoryItem on Story {
  type
  name
  storyId
  sectionType
  isUp
  isAdult
  shortDesc
  authorName
  playType
  isFinished
  onHiatus
  mainImageFile {
    link
  }
}
    `;
export const GenerationStoryFragmentDoc = gql`
    fragment GenerationStory on HomeSection {
  title
  key
  stories {
    ...GenerationStoryItem
  }
}
    ${GenerationStoryItemFragmentDoc}`;
export const GenerationStoryListDocument = gql`
    query GenerationStoryList {
  getHomeSections(data: {type: UserGeneration}) {
    ...GenerationStory
  }
}
    ${GenerationStoryFragmentDoc}`;

/**
 * __useGenerationStoryListQuery__
 *
 * To run a query within a React component, call `useGenerationStoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerationStoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerationStoryListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGenerationStoryListQuery(baseOptions?: Apollo.QueryHookOptions<GenerationStoryListQuery, GenerationStoryListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerationStoryListQuery, GenerationStoryListQueryVariables>(GenerationStoryListDocument, options);
      }
export function useGenerationStoryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerationStoryListQuery, GenerationStoryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerationStoryListQuery, GenerationStoryListQueryVariables>(GenerationStoryListDocument, options);
        }
export type GenerationStoryListQueryHookResult = ReturnType<typeof useGenerationStoryListQuery>;
export type GenerationStoryListLazyQueryHookResult = ReturnType<typeof useGenerationStoryListLazyQuery>;
export type GenerationStoryListQueryResult = Apollo.QueryResult<GenerationStoryListQuery, GenerationStoryListQueryVariables>;