import * as Types from '../../../../baseType';

import { gql } from '@apollo/client';
import { StoryVerticalCoverDtoFragmentDoc } from '../../../fragments/__generated__/home.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NewStoryItemFragment = { __typename?: 'Story', storyId: number, name: string, type: Types.Story_Type, onHiatus: boolean, isFinished: boolean, isOriginal: boolean, isNew: boolean, isUp: boolean, isAdult: boolean, authorName: string, isTimeLeapFree: boolean, shortDesc?: string | null, sectionType: Types.Story_Section_Type, playType: Types.Story_Play_Type, mainImageFile?: { __typename?: 'File', link: string } | null, introImageFile?: { __typename?: 'File', link: string } | null, storyWeekdays?: Array<{ __typename?: 'StoryWeekday', weekday: Types.Story_Weekday }> | null, genreKey?: { __typename?: 'StoryGenre', text: { __typename?: 'LocalizeKey', key: string } } | null };

export type NewStoryListQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  version?: Types.InputMaybe<Types.Scalars['Int']>;
  playType?: Types.InputMaybe<Types.Story_Play_Type>;
}>;


export type NewStoryListQuery = { __typename?: 'Query', listStoryByWebV3: { __typename?: 'ListableStory', totalCount: number, page: number, pageSize: number, version?: number | null, list: Array<{ __typename?: 'Story', type: Types.Story_Type, storyId: number, name: string, authorName: string, onHiatus: boolean, isFinished: boolean, isNew: boolean, isUp: boolean, isTimeLeapFree: boolean, playType: Types.Story_Play_Type, representedAt?: any | null, sectionType: Types.Story_Section_Type, isAdult: boolean, mainImageFile?: { __typename?: 'File', link: string } | null, storyWeekdays?: Array<{ __typename?: 'StoryWeekday', weekday: Types.Story_Weekday }> | null, genreKey?: { __typename?: 'StoryGenre', text: { __typename?: 'LocalizeKey', key: string } } | null, language?: { __typename?: 'Language', display: string, code: string } | null }> } };

export const NewStoryItemFragmentDoc = gql`
    fragment NewStoryItem on Story {
  storyId
  name
  type
  onHiatus
  isFinished
  isOriginal
  isNew
  isUp
  isAdult
  mainImageFile {
    link
  }
  introImageFile {
    link
  }
  storyWeekdays {
    weekday
  }
  genreKey {
    text {
      key
    }
  }
  authorName
  isTimeLeapFree
  shortDesc
  sectionType
  playType
}
    `;
export const NewStoryListDocument = gql`
    query NewStoryList($page: Int, $version: Int, $playType: STORY_PLAY_TYPE) {
  listStoryByWebV3(
    data: {page: $page, playType: $playType, version: $version, pageSize: 15, uiSectionType: New, sorting: RANDOM}
  ) {
    totalCount
    page
    pageSize
    version
    list {
      ...StoryVerticalCoverDto
    }
  }
}
    ${StoryVerticalCoverDtoFragmentDoc}`;

/**
 * __useNewStoryListQuery__
 *
 * To run a query within a React component, call `useNewStoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewStoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewStoryListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      version: // value for 'version'
 *      playType: // value for 'playType'
 *   },
 * });
 */
export function useNewStoryListQuery(baseOptions?: Apollo.QueryHookOptions<NewStoryListQuery, NewStoryListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewStoryListQuery, NewStoryListQueryVariables>(NewStoryListDocument, options);
      }
export function useNewStoryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewStoryListQuery, NewStoryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewStoryListQuery, NewStoryListQueryVariables>(NewStoryListDocument, options);
        }
export type NewStoryListQueryHookResult = ReturnType<typeof useNewStoryListQuery>;
export type NewStoryListLazyQueryHookResult = ReturnType<typeof useNewStoryListLazyQuery>;
export type NewStoryListQueryResult = Apollo.QueryResult<NewStoryListQuery, NewStoryListQueryVariables>;