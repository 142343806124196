import React from 'react';
import { usePlayTypeContext } from '@/provider/PlayTypeProvider';
import { useMonthlyStoryListQuery } from '@/operations/queries/home/__generated__/useMonthlyStoryList.generated';
import { formatMoreLink, getHomeSectionName } from '@common/utils';

import StoryItem from '@components/main/common/storyItem/StoryItem';
import StoryWrapper from '@components/main/common/StoryWrapper';
import SectionTitle from '@components/main/common/title/SectionTitle';
import { CarouselStory } from '@components/commons';

interface IMonthlyStory {
  title: string;
}

const TopStory = ({ title }: IMonthlyStory) => {
  const { playType } = usePlayTypeContext();
  const { data } = useMonthlyStoryListQuery({
    variables: {
      playType,
    },
  });

  if (!data?.listStoryByWebV3.list.length) {
    return null;
  }

  return (
    <StoryWrapper>
      <SectionTitle
        title={title}
        showMoreButton
        moreLink={formatMoreLink({ sectionName: 'monthly', playType })}
      />
      <CarouselStory section={getHomeSectionName({ playType, section: title })}>
        {data?.listStoryByWebV3.list.map((item, index, array) => (
          <StoryItem
            key={item.storyId}
            section={getHomeSectionName({ playType, section: title })}
            index={index}
            data={item}
            isFirstItem={index === 0}
            isLastItem={index === array.length - 1}
            showTopLabel
          />
        ))}
      </CarouselStory>
    </StoryWrapper>
  );
};

export default TopStory;
