import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { css } from '@emotion/react';
import { AdultLabelIcon } from 'public/assets';
import { useAppDispatch } from '@store/hooks';
import {
  setCurrentStoryLabelText,
  setPreviousSection,
} from '@slices/eventSlice';
import { getStatusText, getLabelText } from '@common/utils';
import { HOME_TAB_TYPE } from '@customTypes/home';
import SPImage from '@components/commons/image/SPImage';
import { Section } from '@customTypes/event';

import StoryItemTitle from '../title/StoryItemTitle';
import StoryItemLink from './link/StoryItemLink';
import BandLabel from '../label/BandLabel';
import TopLabel from '@/components/main/common/label/TopLabel';
import { StoryVerticalCoverDtoFragment } from '@/operations/fragments/__generated__/home.generated';
import { Story_Play_Type } from '@/baseType';

interface IStoryItem {
  data: StoryVerticalCoverDtoFragment;
  index: number;
  section: Section;
  isFirstItem?: boolean;
  isLastItem?: boolean;
  showTopLabel?: boolean;
  showInteractiveBanner?: boolean;
}

const StoryItem = ({
  data,
  index,
  section,
  isFirstItem,
  isLastItem,
  showTopLabel,
  showInteractiveBanner,
}: IStoryItem) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const setEventParams = () => {
    dispatch(
      setCurrentStoryLabelText(
        getLabelText({
          currentHomeTab: HOME_TAB_TYPE.Home,
          isFinished: data.isFinished,
          isOnHiatus: data.onHiatus,
          isUp: data.isUp,
        }),
      ),
    );
    section && dispatch(setPreviousSection(section));
  };

  return (
    <div
      css={wrapper({ isFirstItem, isLastItem })}
      data-name={data.name}
      data-index={index}
      data-id={data.storyId}
      data-label={getLabelText({
        currentHomeTab: HOME_TAB_TYPE.Home,
        isFinished: data.isFinished,
        isOnHiatus: data.onHiatus,
        isUp: data.isUp,
      })}
      data-status={getStatusText(data)}
      data-content-type={
        data.playType === Story_Play_Type.Epub ? 'novel' : 'interactive'
      }
    >
      <StoryItemLink
        storyId={data.storyId}
        isAdult={data.isAdult}
        setEventParams={setEventParams}
      >
        <div className="thumbnail">
          <SPImage
            src={data.mainImageFile?.link ?? ''}
            layout="fill"
            alt={data.name}
          />
          {data.isAdult && (
            <div className="adult-sign">
              <AdultLabelIcon width={24} height={24} />
            </div>
          )}
          {showTopLabel && <TopLabel ranking={index + 1} />}
          {showInteractiveBanner && (
            <div className="labelContainer">
              <BandLabel text={t('common_screen_title_interactive')} />
            </div>
          )}
        </div>
        <StoryItemTitle
          title={data?.name}
          isShowUp={data?.isUp}
          subTitle={`${t(data?.genreKey?.text?.key ?? '') || '-'} ・ ${
            data?.authorName
          }`}
        />
      </StoryItemLink>
    </div>
  );
};

interface IWrapper {
  isFirstItem?: boolean;
  isLastItem?: boolean;
}

const wrapper = ({ isFirstItem, isLastItem }: IWrapper) => css`
  display: flex;
  flex-direction: column;
  width: 8.5625rem;
  text-align: left;
  cursor: pointer;
  margin-left: 0.4375rem;

  ${isFirstItem && `margin-left: 1.1875em;`}
  ${isLastItem && `margin-right: 1.1875em;`}

  .thumbnail {
    position: relative;
    width: 8.625rem;
    height: 12.9375rem;

    img {
      border-radius: 0.22rem;
      image-rendering: auto;
    }
  }

  .adult-sign {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
  }
  .labelContainer {
    display: flex;
    align-items: center;
    padding: 0.25rem;
  }
`;

export default StoryItem;
