import React, { ReactNode } from 'react';
import { css } from '@emotion/react';

interface IStoryWrapper {
  children: ReactNode;
  backgroundColor?: string;
}

const StoryWrapper = ({ children, backgroundColor }: IStoryWrapper) => {
  return <div css={container({ backgroundColor })}>{children}</div>;
};

interface IContainer {
  backgroundColor?: string;
}

const container = ({ backgroundColor }: IContainer) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2.5em 0.9375em 1.5em;
  ${backgroundColor && `background: ${backgroundColor}`};
`;

export default StoryWrapper;
