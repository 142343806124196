import * as Types from '../../../../baseType';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HomeStoryGenreItemFragment = { __typename?: 'StoryGenre', type: string, text: { __typename?: 'LocalizeKey', key: string, ko_KR: string }, title?: { __typename?: 'LocalizeKey', key: string, ko_KR: string } | null, subTitle?: { __typename?: 'LocalizeKey', key: string, ko_KR: string } | null };

export type SectionStoryGenresQueryVariables = Types.Exact<{
  tab: Types.Scalars['String'];
}>;


export type SectionStoryGenresQuery = { __typename?: 'Query', sectionStoryGenres: Array<{ __typename?: 'StoryGenre', type: string, text: { __typename?: 'LocalizeKey', key: string, ko_KR: string }, title?: { __typename?: 'LocalizeKey', key: string, ko_KR: string } | null, subTitle?: { __typename?: 'LocalizeKey', key: string, ko_KR: string } | null }> };

export const HomeStoryGenreItemFragmentDoc = gql`
    fragment homeStoryGenreItem on StoryGenre {
  type
  text {
    key
    ko_KR
  }
  title {
    key
    ko_KR
  }
  subTitle {
    key
    ko_KR
  }
}
    `;
export const SectionStoryGenresDocument = gql`
    query sectionStoryGenres($tab: String!) {
  sectionStoryGenres(data: {tab: $tab}) {
    ...homeStoryGenreItem
  }
}
    ${HomeStoryGenreItemFragmentDoc}`;

/**
 * __useSectionStoryGenresQuery__
 *
 * To run a query within a React component, call `useSectionStoryGenresQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectionStoryGenresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectionStoryGenresQuery({
 *   variables: {
 *      tab: // value for 'tab'
 *   },
 * });
 */
export function useSectionStoryGenresQuery(baseOptions: Apollo.QueryHookOptions<SectionStoryGenresQuery, SectionStoryGenresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SectionStoryGenresQuery, SectionStoryGenresQueryVariables>(SectionStoryGenresDocument, options);
      }
export function useSectionStoryGenresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SectionStoryGenresQuery, SectionStoryGenresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SectionStoryGenresQuery, SectionStoryGenresQueryVariables>(SectionStoryGenresDocument, options);
        }
export type SectionStoryGenresQueryHookResult = ReturnType<typeof useSectionStoryGenresQuery>;
export type SectionStoryGenresLazyQueryHookResult = ReturnType<typeof useSectionStoryGenresLazyQuery>;
export type SectionStoryGenresQueryResult = Apollo.QueryResult<SectionStoryGenresQuery, SectionStoryGenresQueryVariables>;