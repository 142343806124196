import * as Types from '../../../../baseType';

import { gql } from '@apollo/client';
import { StoryHorizontalCoverDtoFragmentDoc } from '../../../fragments/__generated__/home.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FavoriteHorizontalCoverStoryQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type FavoriteHorizontalCoverStoryQuery = { __typename?: 'Query', listUserLikeStoryBundle: { __typename?: 'ListableStory', totalCount: number, page: number, pageSize: number, list: Array<{ __typename?: 'Story', storyId: number, name: string, oneLineDesc: string, authorName: string, numViews: number, numLikes: number, numComment: number, onHiatus: boolean, isFinished: boolean, isUp: boolean, isAdult: boolean, isTimeLeapFree: boolean, playType: Types.Story_Play_Type, mainImageFile?: { __typename?: 'File', link: string } | null, genreKey?: { __typename?: 'StoryGenre', text: { __typename?: 'LocalizeKey', key: string, ko_KR: string } } | null }> } };


export const FavoriteHorizontalCoverStoryDocument = gql`
    query FavoriteHorizontalCoverStory($page: Int) {
  listUserLikeStoryBundle(data: {page: $page, pageSize: 6}) {
    totalCount
    page
    pageSize
    list {
      ...StoryHorizontalCoverDto
    }
  }
}
    ${StoryHorizontalCoverDtoFragmentDoc}`;

/**
 * __useFavoriteHorizontalCoverStoryQuery__
 *
 * To run a query within a React component, call `useFavoriteHorizontalCoverStoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFavoriteHorizontalCoverStoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFavoriteHorizontalCoverStoryQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useFavoriteHorizontalCoverStoryQuery(baseOptions?: Apollo.QueryHookOptions<FavoriteHorizontalCoverStoryQuery, FavoriteHorizontalCoverStoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FavoriteHorizontalCoverStoryQuery, FavoriteHorizontalCoverStoryQueryVariables>(FavoriteHorizontalCoverStoryDocument, options);
      }
export function useFavoriteHorizontalCoverStoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FavoriteHorizontalCoverStoryQuery, FavoriteHorizontalCoverStoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FavoriteHorizontalCoverStoryQuery, FavoriteHorizontalCoverStoryQueryVariables>(FavoriteHorizontalCoverStoryDocument, options);
        }
export type FavoriteHorizontalCoverStoryQueryHookResult = ReturnType<typeof useFavoriteHorizontalCoverStoryQuery>;
export type FavoriteHorizontalCoverStoryLazyQueryHookResult = ReturnType<typeof useFavoriteHorizontalCoverStoryLazyQuery>;
export type FavoriteHorizontalCoverStoryQueryResult = Apollo.QueryResult<FavoriteHorizontalCoverStoryQuery, FavoriteHorizontalCoverStoryQueryVariables>;