import React, { useRef } from 'react';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { Section } from '@customTypes/event';
import { useVisibleStoryItemEvent } from '@/hooks/useVisibleStoryItemEvent';

interface IAllStoryGrid {
  section: Section;
  children: JSX.Element[] | JSX.Element;
}

const GenreStoryGrid = ({ section, children }: IAllStoryGrid) => {
  const storyListRef = useRef<HTMLDivElement | null>(null);
  useVisibleStoryItemEvent({ storyListRef, section });

  return (
    <section css={container}>
      <div className="story-grid-wrapper">
        <div className="story-grid" ref={storyListRef}>
          {children}
        </div>
      </div>
    </section>
  );
};

const container = css`
  width: 100%;

  .story-grid-wrapper {
    width: 100%;

    .story-grid {
      width: 100%;
      display: grid;
      justify-content: center;
      grid-template-columns: repeat(auto-fill, minmax(6.9375rem, 7.5rem));
      grid-gap: 0.5rem;
    }
  }

  @media screen and (max-width: ${theme.devices.mobile}) {
    .story-grid-wrapper {
      .story-grid {
        grid-template-columns: repeat(3, minmax(0, 6.9375rem));
      }
    }
  }
`;
export default GenreStoryGrid;
