import React from 'react';
import { css } from '@emotion/react';
import { getStatusText } from '@common/utils';

import StoryItemTitle from '../title/StoryItemTitle';
import SPImage from '@components/commons/image/SPImage';
import StoryItemLink from './link/StoryItemLink';
import { Story_Play_Type } from '@/baseType';
import { GenerationStoryItemFragment } from '@/operations/queries/home/__generated__/useGenerationStoryList.generated';

interface ICardStoryItem {
  data: GenerationStoryItemFragment;
  index: number;
}

const SmallCardStoryItem = ({ data, index }: ICardStoryItem) => {
  return (
    <div
      data-name={data.name}
      data-index={index}
      data-id={data.storyId}
      data-status={getStatusText(data)}
      data-content-type={
        data.playType === Story_Play_Type.Epub ? 'novel' : 'interactive'
      }
    >
      <StoryItemLink storyId={data.storyId} isAdult={data.isAdult}>
        <div css={wrapper}>
          <div className="thumbnail">
            {data?.mainImageFile?.link && (
              <SPImage
                src={data.mainImageFile.link}
                objectFit="cover"
                alt={data.name}
                width={80}
                height={80}
              />
            )}
          </div>
          <StoryItemTitle
            title={data?.name}
            subTitle={data?.shortDesc}
            author={data?.authorName}
            isShowUp={data.isUp}
            isShowAdultIcon={data.isAdult}
          />
        </div>
      </StoryItemLink>
    </div>
  );
};

const wrapper = css`
  display: flex;
  gap: 0.625em;
  width: 18.75em;
  cursor: pointer;

  .thumbnail {
    width: 5em;
    height: 5em;
    flex-shrink: 0;

    img {
      border-radius: 0.25em;
      image-rendering: auto;
    }
  }
`;

export default SmallCardStoryItem;
