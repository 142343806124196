import React from 'react';
import { useGenerationStoryListQuery } from '@/operations/queries/home/__generated__/useGenerationStoryList.generated';

import MultiLineStoryList from '@components/main/common/list/MultiLineStoryList';
import StoryRecommendation from './StoryRecommendation';
import { getHomeSectionName } from '@/common/utils';
import { usePlayTypeContext } from '@/provider/PlayTypeProvider';
import { useGetUserQuery } from '@/operations/queries/user/__generated__/getUser.generated';
import useTranslation from 'next-translate/useTranslation';

const GenerationStoryList = () => {
  const { data } = useGenerationStoryListQuery();
  const { playType } = usePlayTypeContext();
  const { data: userData } = useGetUserQuery();
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  return (
    <>
      {data?.getHomeSections.length ? (
        data?.getHomeSections.map((generation) => (
          <MultiLineStoryList
            key={generation.key}
            section={getHomeSectionName({
              playType,
              section: `연령별_${generation.key}`,
            })}
            data={generation}
            title={
              userData?.getUser?.isAnonymous || !userData?.getUser?.name
                ? t('home_screen_title_recommend')
                : t('home_screen_title_recommend_with_nickname', {
                    value: userData?.getUser?.name,
                  })
            }
          />
        ))
      ) : (
        <StoryRecommendation />
      )}
    </>
  );
};

export default GenerationStoryList;
