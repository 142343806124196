import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { usePlayTypeContext } from '@/provider/PlayTypeProvider';
import { useAdultStoryListQuery } from '@/operations/queries/home/__generated__/useAdultStoryList.generated';
import { formatMoreLink, getHomeSectionName } from '@common/utils';

import StoryWrapper from '@components/main/common/StoryWrapper';
import SectionTitle from '@components/main/common/title/SectionTitle';
import GenreStoryGrid from '@components/main/home/grid/GenreStoryGrid';
import SmallStoryItem from '../../common/storyItem/SmallStoryItem';
import * as Types from '../../../../baseType';
import { useGetUserQuery } from '@/operations/queries/user/__generated__/getUser.generated';

const AdultStory = () => {
  const { t } = useTranslation();
  const { playType } = usePlayTypeContext();

  const { data: userData } = useGetUserQuery();

  const { data } = useAdultStoryListQuery({
    variables: {
      playType,
      sorting: 'DAILY_RANK_ASC' as Types.Story_Sorting,
    },
  });

  // 성인인증을 하지 않았거나 성인인증이 만료된 경우 성인 스토리를 보여주지 않는다.
  if (
    !data?.listStoryByWebV3.list.length ||
    !userData?.getUser?.certificatedAt ||
    userData?.getUser?.isExpiredCertification
  ) {
    return null;
  }

  return (
    <StoryWrapper>
      <SectionTitle
        title={t('home_screen_title_stories_for_adult')}
        showMoreButton
        moreLink={formatMoreLink({ sectionName: 'adult', playType })}
      />
      <GenreStoryGrid
        section={getHomeSectionName({
          playType,
          section: t('home_screen_title_stories_for_adult'),
        })}
      >
        {data?.listStoryByWebV3.list?.slice(0, 6).map((item, index) => (
          <SmallStoryItem
            index={index}
            section={getHomeSectionName({
              playType,
              section: t('home_screen_title_stories_for_adult'),
            })}
            key={item.storyId}
            data={item}
            hasTitle
            showInteractiveBanner={item.playType === 'Interactive' && !playType}
          />
        ))}
      </GenreStoryGrid>
    </StoryWrapper>
  );
};

export default AdultStory;
