import * as Types from '../../../../baseType';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RankingStoryItemFragment = { __typename?: 'WeeklyStoryRanking', ranking: number, amountChange: number, isNew: boolean, story: { __typename?: 'Story', storyId: number, name: string, authorName: string, onHiatus: boolean, isFinished: boolean, isUp: boolean, playType: Types.Story_Play_Type, isAdult: boolean, mainImageFile?: { __typename?: 'File', link: string } | null, genreKey?: { __typename?: 'StoryGenre', text: { __typename?: 'LocalizeKey', key: string } } | null } };

export type RankingStoryListQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']>;
  playType?: Types.InputMaybe<Types.Story_Play_Type>;
}>;


export type RankingStoryListQuery = { __typename?: 'Query', listWeeklyStoryRankingV3: { __typename?: 'ListableWeeklyStoryRanking', totalCount: number, page: number, pageSize: number, list: Array<{ __typename?: 'WeeklyStoryRanking', ranking: number, amountChange: number, isNew: boolean, story: { __typename?: 'Story', storyId: number, name: string, authorName: string, onHiatus: boolean, isFinished: boolean, isUp: boolean, playType: Types.Story_Play_Type, isAdult: boolean, mainImageFile?: { __typename?: 'File', link: string } | null, genreKey?: { __typename?: 'StoryGenre', text: { __typename?: 'LocalizeKey', key: string } } | null } }> } };

export const RankingStoryItemFragmentDoc = gql`
    fragment RankingStoryItem on WeeklyStoryRanking {
  ranking
  amountChange
  isNew
  story {
    storyId
    name
    authorName
    onHiatus
    isFinished
    isUp
    playType
    isAdult
    mainImageFile {
      link
    }
    genreKey {
      text {
        key
      }
    }
  }
}
    `;
export const RankingStoryListDocument = gql`
    query RankingStoryList($page: Int, $pageSize: Int, $playType: STORY_PLAY_TYPE) {
  listWeeklyStoryRankingV3(
    data: {page: $page, pageSize: $pageSize, playType: $playType}
  ) {
    totalCount
    page
    pageSize
    list {
      ...RankingStoryItem
    }
  }
}
    ${RankingStoryItemFragmentDoc}`;

/**
 * __useRankingStoryListQuery__
 *
 * To run a query within a React component, call `useRankingStoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRankingStoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRankingStoryListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      playType: // value for 'playType'
 *   },
 * });
 */
export function useRankingStoryListQuery(baseOptions?: Apollo.QueryHookOptions<RankingStoryListQuery, RankingStoryListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RankingStoryListQuery, RankingStoryListQueryVariables>(RankingStoryListDocument, options);
      }
export function useRankingStoryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RankingStoryListQuery, RankingStoryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RankingStoryListQuery, RankingStoryListQueryVariables>(RankingStoryListDocument, options);
        }
export type RankingStoryListQueryHookResult = ReturnType<typeof useRankingStoryListQuery>;
export type RankingStoryListLazyQueryHookResult = ReturnType<typeof useRankingStoryListLazyQuery>;
export type RankingStoryListQueryResult = Apollo.QueryResult<RankingStoryListQuery, RankingStoryListQueryVariables>;