import React, { useRef } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { usePlayTypeContext } from '@/provider/PlayTypeProvider';
import { useRankingStoryListQuery } from '@/operations/queries/home/__generated__/useRankingStoryList.generated';
import { css } from '@emotion/react';
import { formatMoreLink, getHomeSectionName } from '@common/utils';

import StoryItemTitleWithRanking from '@components/main/common/title/StoryItemTitleWithRanking';
import StoryItemLink from '@/components/main/common/storyItem/link/StoryItemLink';
import StoryWrapper from '@components/main/common/StoryWrapper';
import SectionTitle from '@components/main/common/title/SectionTitle';
import { SPImage } from '@components/commons';
import { useAppDispatch } from '@/store/hooks';
import { setPreviousSection } from '@/slices/eventSlice';
import { Story_Play_Type } from '@/baseType';
import { useVisibleStoryItemEvent } from '@/hooks/useVisibleStoryItemEvent';

const RankingStory = () => {
  const { playType } = usePlayTypeContext();
  const { data } = useRankingStoryListQuery({
    variables: {
      playType,
      pageSize: 20,
    },
  });
  const storyListRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  useVisibleStoryItemEvent({
    storyListRef,
    section: getHomeSectionName({ section: '이번 주 급 상승 작품', playType }),
  });

  if (!data?.listWeeklyStoryRankingV3.list.length) {
    return null;
  }

  const setEventParams = () => {
    dispatch(
      setPreviousSection(
        getHomeSectionName({
          playType,
          section: t('home_screen_title_ranking'),
        }),
      ),
    );
  };

  return (
    <StoryWrapper>
      <SectionTitle
        title={t('home_screen_title_ranking')}
        showMoreButton
        moreLink={formatMoreLink({
          sectionName: 'ranking',
          playType,
        })}
      />
      <div css={container} ref={storyListRef}>
        {data?.listWeeklyStoryRankingV3.list.slice(0, 5).map((item, index) => (
          <div
            data-name={item.story.name}
            data-index={index}
            data-id={item.story.storyId}
            data-content-type={
              item.story.playType === Story_Play_Type.Epub
                ? 'novel'
                : 'interactive'
            }
            key={item.story.storyId}
          >
            <StoryItemLink
              storyId={item.story.storyId}
              setEventParams={setEventParams}
            >
              <div className="item-wrapper">
                <div className="thumbnail">
                  <SPImage
                    src={item.story.mainImageFile?.link ?? ''}
                    objectFit="cover"
                    alt={item.story.name}
                    width={52}
                    height={74}
                  />
                </div>
                <StoryItemTitleWithRanking
                  title={item.story.name}
                  subTitle={`${
                    t(item.story?.genreKey?.text.key ?? '') || '-'
                  } ・ ${item.story?.authorName}`}
                  isShowUp={item.story.isUp}
                  isNew={item.isNew}
                  amountChange={item.amountChange}
                  ranking={item.ranking}
                  isAdult={item.story.isAdult}
                />
              </div>
            </StoryItemLink>
          </div>
        ))}
      </div>
    </StoryWrapper>
  );
};

const container = css`
  display: flex;
  flex-direction: column;
  gap: 0.5625rem;

  .item-wrapper {
    display: flex;
    gap: 1rem;
    cursor: pointer;

    .thumbnail {
      width: 3.25rem;
      height: 4.625rem;

      img {
        border-radius: 0.25em;
        image-rendering: auto;
      }
    }
  }
`;

export default RankingStory;
