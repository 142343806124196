import * as Types from '../../../../baseType';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GenreStoryItemFragment = { __typename?: 'Story', type: Types.Story_Type, storyId: number, name: string, authorName: string, onHiatus: boolean, isFinished: boolean, isNew: boolean, isUp: boolean, isTimeLeapFree: boolean, playType: Types.Story_Play_Type, isAdult: boolean, mainImageFile?: { __typename?: 'File', link: string } | null, storyWeekdays?: Array<{ __typename?: 'StoryWeekday', weekday: Types.Story_Weekday }> | null, genreKey?: { __typename?: 'StoryGenre', text: { __typename?: 'LocalizeKey', key: string } } | null };

export type HomeTabGenreStoryListQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  genre: Types.Scalars['String'];
  playType?: Types.InputMaybe<Types.Story_Play_Type>;
}>;


export type HomeTabGenreStoryListQuery = { __typename?: 'Query', listStoryByWebV3: { __typename?: 'ListableStory', totalCount: number, page: number, pageSize: number, version?: number | null, list: Array<{ __typename?: 'Story', type: Types.Story_Type, storyId: number, name: string, authorName: string, onHiatus: boolean, isFinished: boolean, isNew: boolean, isUp: boolean, isTimeLeapFree: boolean, playType: Types.Story_Play_Type, isAdult: boolean, mainImageFile?: { __typename?: 'File', link: string } | null, storyWeekdays?: Array<{ __typename?: 'StoryWeekday', weekday: Types.Story_Weekday }> | null, genreKey?: { __typename?: 'StoryGenre', text: { __typename?: 'LocalizeKey', key: string } } | null }> } };

export const GenreStoryItemFragmentDoc = gql`
    fragment GenreStoryItem on Story {
  type
  storyId
  name
  authorName
  onHiatus
  isFinished
  isNew
  isUp
  isTimeLeapFree
  playType
  isAdult
  mainImageFile {
    link
  }
  storyWeekdays {
    weekday
  }
  genreKey {
    text {
      key
    }
  }
}
    `;
export const HomeTabGenreStoryListDocument = gql`
    query HomeTabGenreStoryList($page: Int, $genre: String!, $playType: STORY_PLAY_TYPE) {
  listStoryByWebV3(
    data: {page: $page, pageSize: 6, genre: $genre, playType: $playType}
  ) {
    totalCount
    page
    pageSize
    version
    list {
      ...GenreStoryItem
    }
  }
}
    ${GenreStoryItemFragmentDoc}`;

/**
 * __useHomeTabGenreStoryListQuery__
 *
 * To run a query within a React component, call `useHomeTabGenreStoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeTabGenreStoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeTabGenreStoryListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      genre: // value for 'genre'
 *      playType: // value for 'playType'
 *   },
 * });
 */
export function useHomeTabGenreStoryListQuery(baseOptions: Apollo.QueryHookOptions<HomeTabGenreStoryListQuery, HomeTabGenreStoryListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeTabGenreStoryListQuery, HomeTabGenreStoryListQueryVariables>(HomeTabGenreStoryListDocument, options);
      }
export function useHomeTabGenreStoryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeTabGenreStoryListQuery, HomeTabGenreStoryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeTabGenreStoryListQuery, HomeTabGenreStoryListQueryVariables>(HomeTabGenreStoryListDocument, options);
        }
export type HomeTabGenreStoryListQueryHookResult = ReturnType<typeof useHomeTabGenreStoryListQuery>;
export type HomeTabGenreStoryListLazyQueryHookResult = ReturnType<typeof useHomeTabGenreStoryListLazyQuery>;
export type HomeTabGenreStoryListQueryResult = Apollo.QueryResult<HomeTabGenreStoryListQuery, HomeTabGenreStoryListQueryVariables>;