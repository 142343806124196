import { gql } from '@apollo/client';
import {
  useFavoriteHorizontalCoverStoryQuery as useQuery,
  FavoriteHorizontalCoverStoryQueryVariables,
} from './__generated__/useFavoriteStoryList.generated';

export const FAVORITE_STORY_LIST = gql`
  query FavoriteHorizontalCoverStory($page: Int) {
    listUserLikeStoryBundle(data: { page: $page, pageSize: 6 }) {
      totalCount
      page
      pageSize
      list {
        ...StoryHorizontalCoverDto
      }
    }
  }
`;

export const useFavoriteStoryListQuery = (
  variables?: FavoriteHorizontalCoverStoryQueryVariables,
) => {
  const { data, fetchMore } = useQuery({
    variables,
  });

  const hasNext =
    data &&
    data.listUserLikeStoryBundle.totalCount >
      data.listUserLikeStoryBundle.list.length;

  return { data, hasNext, fetchMore };
};
