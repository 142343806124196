import React from 'react';
import { usePlayTypeContext } from '@/provider/PlayTypeProvider';
import { useNewStoryListQuery } from '@/operations/queries/home/__generated__/useNewStoryList.generated';
import { formatMoreLink, getHomeSectionName } from '@common/utils';

import StoryItem from '@components/main/common/storyItem/StoryItem';
import StoryWrapper from '@components/main/common/StoryWrapper';
import SectionTitle from '@components/main/common/title/SectionTitle';
import { CarouselStory } from '@components/commons';

interface INewStory {
  title: string;
}

const NewStory = ({ title }: INewStory) => {
  const { playType: homeTabType } = usePlayTypeContext();
  const { data } = useNewStoryListQuery({
    variables: { playType: homeTabType },
  });

  if (!data?.listStoryByWebV3.list.length) {
    return null;
  }

  return (
    <StoryWrapper>
      <SectionTitle
        title={title}
        moreLink={formatMoreLink({
          sectionName: 'new',
          playType: homeTabType,
        })}
        showMoreButton={true}
      />
      <CarouselStory
        section={getHomeSectionName({
          playType: homeTabType,
          section: title,
        })}
      >
        {data.listStoryByWebV3.list.map((item, index, array) => (
          <StoryItem
            key={item.storyId}
            section={getHomeSectionName({
              playType: homeTabType,
              section: title,
            })}
            index={index}
            data={item}
            isFirstItem={index === 0}
            isLastItem={index === array.length - 1}
            showInteractiveBanner={
              item.playType === 'Interactive' && !homeTabType
            }
          />
        ))}
      </CarouselStory>
    </StoryWrapper>
  );
};

export default NewStory;
