import React, { useEffect, useRef } from 'react';
import { useFeaturedStoryQuery } from '@/operations/queries/home/__generated__/useFeaturedStory.generated';
import { viewStoryThumbnail } from '@lib/eventManager';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { Story_Play_Type } from '@/baseType';
import { getHomeSectionName } from '@/common/utils';
import { usePlayTypeContext } from '@/provider/PlayTypeProvider';
import { useAppDispatch } from '@/store/hooks';
import { setPreviousSection } from '@/slices/eventSlice';

import StoryItemLink from '@components/main/common/storyItem/link/StoryItemLink';
import SPImage from '@components/commons/image/SPImage';

const FeaturedStoryItem = () => {
  const { data } = useFeaturedStoryQuery();
  const itemRef = useRef<HTMLDivElement | null>(null);
  const { playType } = usePlayTypeContext();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!itemRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (
            entry.isIntersecting &&
            itemRef.current &&
            entry.target instanceof HTMLElement
          ) {
            viewStoryThumbnail({
              section: getHomeSectionName({ playType, section: '피쳐드' }),
              index: '0',
              story: data?.getFeatured?.story.name,
              storyId: String(data?.getFeatured?.storyId),
              contentType:
                playType === Story_Play_Type.Epub ? 'novel' : 'interactive',
            });
          }
        });
      },
      {
        threshold: 1,
      },
    );

    observer.observe(itemRef.current as Element);

    return () => {
      observer.disconnect();
    };
  }, [playType]);

  if (!data?.getFeatured) {
    return null;
  }

  const setEventParams = () => {
    dispatch(
      setPreviousSection(getHomeSectionName({ playType, section: '피쳐드' })),
    );
  };

  return (
    <StoryItemLink
      storyId={data.getFeatured.storyId}
      setEventParams={setEventParams}
    >
      <div css={wrapper} ref={itemRef}>
        <SPImage
          src={data?.getFeatured?.coverImageFile?.link || ''}
          objectFit="cover"
          height="343"
          width="343"
        />
      </div>
    </StoryItemLink>
  );
};

const wrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: ${theme.maxWidth};
  height: 100%;
  margin-bottom: 1em;
  background: ${theme.colors.gray100};

  img {
    border: 1px solid red;
    border-radius: 0.22em;
    image-rendering: auto;
    cursor: pointer;
  }
`;

export default FeaturedStoryItem;
