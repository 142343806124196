import { gql } from '@apollo/client';
import {
  UserHistoryListQueryVariables,
  useUserHistoryListQuery as useQuery,
} from './__generated__/useUserHistoryList.generated';

export const USER_HISTORY_LIST = gql`
  query UserHistoryList($page: Int) {
    listUserStoryHistoryV3(data: { page: $page, pageSize: 6 }) {
      totalCount
      page
      pageSize
      list {
        userStoryHistoryId
        story {
          ...StoryHorizontalCoverDto
        }
      }
    }
  }
`;

export const useUserHistoryListQuery = (
  variables?: UserHistoryListQueryVariables,
) => {
  const { data, fetchMore } = useQuery({
    variables,
  });

  const hasNext =
    data &&
    data?.listUserStoryHistoryV3.totalCount >
      data?.listUserStoryHistoryV3.list.length;

  return { data, fetchMore, hasNext };
};
