import React, { useEffect, useRef } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { StorySearchQuery } from '@/operations/queries/home/__generated__/useSearchStory.generated';
import SmallStoryItem from '@components/main/common/storyItem/SmallStoryItem';
import useInfiniteScroll from '@/hooks/useInfiniteScroll';
import { NetworkStatus } from '@/common/values';
import { SPLoading } from '@/components/commons';
import AllStoryGrid from '../grid/AllStoryGrid';

interface ISearchStory {
  searchText?: string;
  data?: StorySearchQuery;
  fetchMore: (options: { variables: { page: number } }) => void;
  hasNext: boolean;
  networkStatus: number;
  nextPage: number;
}

const SearchStory = ({
  searchText,
  data,
  fetchMore,
  networkStatus,
  hasNext,
  nextPage,
}: ISearchStory) => {
  const { t } = useTranslation();

  const fetchMoreRef = useRef<HTMLDivElement>(null);
  const intersecting = useInfiniteScroll(fetchMoreRef);

  const fetchNextPage = () => {
    if (!data) return;

    fetchMore({
      variables: {
        page: nextPage,
      },
    });
  };

  useEffect(() => {
    if (intersecting && hasNext) {
      fetchNextPage();
    }
  }, [intersecting, hasNext]);

  return (
    <AllStoryGrid
      isTitleTextBlack
      section={'검색'}
      title={
        searchText
          ? `${t('search_screen_label_search_result_from', {
              value: searchText,
            })}`
          : `${t('search_screen_title_total_story')}`
      }
    >
      <>
        {data?.searchStoryByWebV3.list?.map((item, index) => (
          <SmallStoryItem
            index={index}
            section={'검색'}
            key={item.storyId}
            data={item}
            showInteractiveBanner={item.playType === 'Interactive'}
          />
        ))}
        {networkStatus === NetworkStatus.loading && <SPLoading />}
        <div ref={fetchMoreRef} />
      </>
    </AllStoryGrid>
  );
};

export default SearchStory;
