import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { RankDownIcon, RankUpIcon } from 'public/assets';

import StoryItemTitle from './StoryItemTitle';

interface IStoryItemTitleWithRanking {
  title?: string;
  subTitle?: string | null;
  author?: string | null;
  isShowUp?: boolean;
  ranking: number;
  amountChange: number;
  isNew: boolean;
  isAdult: boolean;
}

const StoryItemTitleWithRanking = ({
  ranking,
  amountChange,
  isNew,
  isAdult,
  ...restProps
}: IStoryItemTitleWithRanking) => {
  return (
    <div css={container}>
      <RankingWrapper
        ranking={ranking}
        amountChange={amountChange}
        isNew={isNew}
      />
      <StoryItemTitle {...restProps} isShowAdultIcon={isAdult} />
    </div>
  );
};

const container = css`
  display: flex;
  align-items: center;
`;

interface IRankingWrapper {
  amountChange: number;
  isNew: boolean;
  ranking: number;
}

const RankingWrapper = ({ ranking, amountChange, isNew }: IRankingWrapper) => {
  const renderRankingStatus = useMemo(() => {
    if (isNew) {
      return <div className="new">{'new'}</div>;
    } else if (amountChange < 0) {
      return (
        <div className="amount-change-wrapper">
          <RankDownIcon />
          <span className="number down">
            {amountChange.toString().replace('-', '')}
          </span>
        </div>
      );
    } else if (amountChange > 0) {
      return (
        <div className="amount-change-wrapper">
          <RankUpIcon />
          <span className="number up">{amountChange}</span>
        </div>
      );
    } else if (amountChange === 0) {
      return <div className="equal-icon">{'='}</div>;
    }
  }, [ranking, amountChange, isNew]);

  return (
    <div css={rankingStatusIconWrapper}>
      <div className="ranking-number">{ranking}</div>
      {renderRankingStatus}
    </div>
  );
};

const rankingStatusIconWrapper = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 1rem;
  margin-right: 0.75rem;

  .amount-change-wrapper {
    display: flex;
    align-items: center;
    gap: 0.1563rem;

    .number {
      font-weight: 700;
      font-size: 0.5rem;
      line-height: 1rem;
    }

    .number.up {
      color: ${theme.colors.emerald500};
    }

    .number.down {
      color: ${theme.colors.red500};
    }
  }

  .ranking-number {
    color: ${theme.colors.gray800};
    ${theme.font.header.subhead1};
  }

  .equal-icon {
    color: ${theme.colors.gray500};
    ${theme.font.body.caption};
  }

  .new {
    color: ${theme.colors.primary300};
    ${theme.font.body.caption};
  }
`;

export default StoryItemTitleWithRanking;
