import * as Types from '../../../../baseType';

import { gql } from '@apollo/client';
import { StoryVerticalCoverDtoFragmentDoc } from '../../../fragments/__generated__/home.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OriginalStoryListQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  playType?: Types.InputMaybe<Types.Story_Play_Type>;
}>;


export type OriginalStoryListQuery = { __typename?: 'Query', listStoryByWebV3: { __typename?: 'ListableStory', totalCount: number, page: number, pageSize: number, list: Array<{ __typename?: 'Story', type: Types.Story_Type, storyId: number, name: string, authorName: string, onHiatus: boolean, isFinished: boolean, isNew: boolean, isUp: boolean, isTimeLeapFree: boolean, playType: Types.Story_Play_Type, representedAt?: any | null, sectionType: Types.Story_Section_Type, isAdult: boolean, mainImageFile?: { __typename?: 'File', link: string } | null, storyWeekdays?: Array<{ __typename?: 'StoryWeekday', weekday: Types.Story_Weekday }> | null, genreKey?: { __typename?: 'StoryGenre', text: { __typename?: 'LocalizeKey', key: string } } | null, language?: { __typename?: 'Language', display: string, code: string } | null }> } };


export const OriginalStoryListDocument = gql`
    query OriginalStoryList($page: Int, $playType: STORY_PLAY_TYPE) {
  listStoryByWebV3(
    data: {page: $page, playType: $playType, pageSize: 15, uiSectionType: Original}
  ) {
    totalCount
    page
    pageSize
    list {
      ...StoryVerticalCoverDto
    }
  }
}
    ${StoryVerticalCoverDtoFragmentDoc}`;

/**
 * __useOriginalStoryListQuery__
 *
 * To run a query within a React component, call `useOriginalStoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOriginalStoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOriginalStoryListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      playType: // value for 'playType'
 *   },
 * });
 */
export function useOriginalStoryListQuery(baseOptions?: Apollo.QueryHookOptions<OriginalStoryListQuery, OriginalStoryListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OriginalStoryListQuery, OriginalStoryListQueryVariables>(OriginalStoryListDocument, options);
      }
export function useOriginalStoryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OriginalStoryListQuery, OriginalStoryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OriginalStoryListQuery, OriginalStoryListQueryVariables>(OriginalStoryListDocument, options);
        }
export type OriginalStoryListQueryHookResult = ReturnType<typeof useOriginalStoryListQuery>;
export type OriginalStoryListLazyQueryHookResult = ReturnType<typeof useOriginalStoryListLazyQuery>;
export type OriginalStoryListQueryResult = Apollo.QueryResult<OriginalStoryListQuery, OriginalStoryListQueryVariables>;